<template>
    <v-app v-if="usuarioLogado">
        <v-navigation-drawer v-model="drawer" app>
            <v-list shaped dense>
                <v-list-item>
                    <v-img :src="require(`@/assets/img/logo_cor.webp`)" alt="" />
                </v-list-item>
                <v-divider style="margin:8px 0;"/>
                <v-menu transition="scroll-y-transition" max-width="190" offset-y>
                    <template v-slot:activator="{on}">
                        <v-list-item v-on="on">
                            <v-list-item-avatar v-if="usuarioSessao.imagem_perfil === 't'">
                                <img v-auth-image="`${apiUrl}usuario/${usuarioSessao.id_usuario}/imagem/thumb.jpg`"/>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>{{usuarioSessao.usuario}}</v-list-item-title>
                                <v-list-item-subtitle>{{usuarioSessao.email}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon>mdi-menu-down</v-icon>
                            </v-list-item-action>
                        </v-list-item>
                    </template>
                    <v-list dense>
                        <!--<v-list-item exact to="/usuario/meus_dados_alterar">
                            <v-list-item-content>
                                <v-list-item-title><v-icon left>mdi-account-details-outline</v-icon>Alterar meus dados</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>-->
                        <v-list-item exact to="/usuario/senha_alterar">
                            <v-list-item-content>
                                <v-list-item-title><v-icon left>mdi-form-textbox-password</v-icon>Alterar minha senha</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item exact to="/usuario/imagem_alterar">
                            <v-list-item-content>
                                <v-list-item-title><v-icon left>mdi-camera</v-icon>Alterar minha foto</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <!--<v-list-item exact to="/ajuda">
                            <v-list-item-content>
                                <v-list-item-title><v-icon left>mdi-help-circle</v-icon>Ajuda</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>-->
                        <v-list-item @click="sair">
                            <v-list-item-content>
                                <v-list-item-title><v-icon left>mdi-logout</v-icon>Sair</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-divider style="margin:8px 0;"/>
                <template v-for="item in menuPrincipal">
                    <v-list-item color="primary" exact :to="item.router" v-if="item.router" link :key="item.title">
                        <v-list-item-icon class="mr-4">
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-group no-action v-else :key="item.title">
                        <template v-slot:activator>
                            <v-list-item-icon class="mr-4">
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{item.title}}</v-list-item-title>
                        </template>
                        <v-list-item exact :to="itemSub.router" v-for="(itemSub, i) in item.itens" :key="i" link>
                            <v-list-item-title v-text="itemSub.title"></v-list-item-title>
                        </v-list-item>
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app :color="usuarioSessao.inadimplente ? '#990000' : 'primary'" dark>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <div class="d-flex align-center">
                <v-toolbar-title>{{title}}</v-toolbar-title>
                <!--<v-img alt="Excellent Global" class="shrink mt-1 hidden-sm-and-down" contain min-width="100" :src="require(`@/assets/img/logo_cor.webp`)" height="40" />-->
            </div>
            <v-spacer/>
            <v-btn v-if="usuarioSessao.inadimplente && (usuarioSessao.id_grupo == 1 || usuarioSessao.id_grupo == 2 || usuarioSessao.id_grupo == 3)" text to="/financeiro/fatura"><v-icon left>mdi-cash</v-icon>Existem faturas em aberto</v-btn>
            <v-app-bar-title v-if="usuarioSessao.inadimplente">saindo em {{tempoDeslogar}}s</v-app-bar-title>
        </v-app-bar>
        <v-main style="background-color: #F8F9FA;">
            <keep-alive>
                <v-container fluid>
                    <keep-alive>
                        <router-view/>
                    </keep-alive>
                </v-container>
            </keep-alive>
        </v-main>
    </v-app>
    <v-app v-else style="background-color: #0E5381;">
        <v-row>
            <v-col cols="6" class="hidden-xs-only" >
                <v-container  fluid class="fill-height">
                    <v-img style="margin:0 auto;" max-width="300px" :src="require(`@/assets/img/logo_branca.png`)" alt="" />
                </v-container>
            </v-col>
            <v-col cols="12" sm="6" style="background-color: #E8EBF6;">
                <v-container fluid class="fill-height">
                    <LoginNovaSenha v-if="$route.path.indexOf('/login/recuperar_senha') > -1"/>
                    <Login v-else />
                </v-container>
            </v-col>
        </v-row>
    </v-app>
</template>

<script>

import {mapState} from "vuex";
import axios from "axios";
import Login from "@/Views/Login/Login";
import LoginNovaSenha from "@/Views/Login/LoginNovaSenha.vue";

export default {
    name: 'App',

    components: {
        LoginNovaSenha,
        Login
    },
    computed : {
        ...mapState(['usuarioLogado', 'usuarioSessao', 'usuarioChave', 'apiUrl'])
    },
    methods : {
        sair() {
            axios.post(`${this.apiUrl}login/sair`, {authorization : this.usuarioSessao.sessao_token}).then(() => {
                this.tempoDeslogar = 60
                clearInterval(this.deslogarInterval)
                this.drawer = false
                this.$store.commit('usuarioLogin', null)
                localStorage.clear()
                delete axios.defaults.headers.common['authorization']
                this.erro = null
                if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                    this.$router.push('/login')
                }
            })
        },
        async validarToken() {
            const json = localStorage.getItem(this.usuarioChave)
            const sessao = JSON.parse(json)
            if (!sessao) {
                this.$store.commit('usuarioLogin', null)
                if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                    return this.$router.push('/login')
                }
            }
            await axios.post(`${this.apiUrl}login/authorization/validar`, {authorization : sessao.sessao_token}).then((res) => {
                if(res.data) {
                    this.$store.commit('usuarioLogin', sessao)
                } else {
                    localStorage.removeItem(this.usuarioChave)
                    if(this.$router.currentRoute.path.indexOf('recuperar_senha') === -1 && this.$router.currentRoute.path !== '/') {
                        return this.$router.push('/login')
                    }
                }
            })
        },
        async getMenuPrincipal() {
            /*if (this.permissao('disciplina_cadastrar') || this.permissao('disciplina_listar') && this.usuarioSessao.id_grupo == '1') {
                item = {title: 'Disciplina', icon: 'mdi-translate', itens: []}
                if (this.permissao('disciplina_cadastrar')) {
                    item.itens.push({title: 'Cadastrar disciplina', router: '/disciplina/cadastrar'})
                }
                if (this.permissao('disciplina_listar')) {
                    item.itens.push({title: 'Listar disciplinas', router: '/disciplina'})
                }
                menu.push(item)
            }*/
            let menu = [{title: 'Dashboard', icon: 'mdi-view-dashboard', router: '/'}];
            let item = {};
            menu.push({title: 'Aulas', icon: 'mdi-lead-pencil', router : '/aula'})
            // Disciplinas
            if (this.permissao('disciplina_listar') && this.usuarioSessao.id_grupo == '1') {
                menu.push({title: 'Disciplina', icon: 'mdi-translate', router : '/disciplina'})
            }
            // Alunos
            if (this.permissao('aluno_cadastrar') || this.permissao('aluno_listar')) {
                menu.push({title: 'Alunos', icon: 'mdi-school', router: '/aluno'})
            }

            // Comercial
            /*item = {};
            item = {title: '* Comercial', icon: 'mdi-clipboard-account', itens: []}

            if (this.permissao('prospect_cadastrar')) {
                item.itens.push({title: '* Cadastrar prospect', router: '/prospect/cadastrar'})
            }
            if (this.permissao('prospect_listar')) {
                item.itens.push({title: '* Todos prospects', router: '/prospect'})
            }
            if (this.permissao('prospect_meu_listar')) {
                item.itens.push({title: '* Prospects', router: '/prospect/meu'})
            }
            menu.push(item)*/
            // Contratos
            /*item = {};
            item = {title: '* Contratos', icon: 'mdi-file-document-edit', itens: []}

            if (this.permissao('contrato_cadastrar')) {
                item.itens.push({title: '* Cadastrar contrato', router: '/contrato/cadastrar'})
            }
            if (this.permissao('contrato_listar')) {
                item.itens.push({title: '* Todos contratos', router: '/contrato'})
            }
            if (this.permissao('contrato_meu_listar')) {
                item.itens.push({title: '* Contratos', router: '/contrato/meu'})
            }
            menu.push(item)*/

            // Avisos
            /*item = {};
            item = {title: '* Avisos', icon: 'mdi-bell', itens: []}

            if (this.permissao('aviso_cadastrar')) {
                item.itens.push({title: '* Cadastrar aviso', router: '/aviso/cadastrar'})
            }
            if (this.permissao('aviso_listar')) {
                item.itens.push({title: '* Todos avisos', router: '/aviso'})
            }
            if (this.permissao('aviso_meu_listar')) {
                item.itens.push({title: '* Avisos', router: '/aviso/meu'})
            }
            menu.push(item)*/

            // Responsáveis
            if (this.permissao('responsavel_cadastrar') || this.permissao('responsavel_listar')) {
                menu.push({title: 'Responsáveis', icon: 'mdi-human-male-child', router: '/responsavel'})
            }
            // Franquias
            if (this.permissao('franquia_cadastrar') || this.permissao('franquia_listar')) {
                menu.push({title: 'Franquias', icon: 'mdi-home-city', router: '/franquia'})
            }
            // Cursos
            if (this.permissao('curso_cadastrar') || this.permissao('curso_listar')) {
                menu.push({title: 'Cursos', icon: 'mdi-book-open-page-variant', router: '/curso'})
            }
            // Turmas
            if (this.permissao('turma_listar')) {
                menu.push({title: 'Turmas', icon: 'mdi-chair-school', router: '/turma'})
            }
            // Financeiro
            if (this.usuarioSessao.id_grupo == '1' || this.usuarioSessao.id_grupo == '2') {
                item = {title: 'Financeiro', icon: 'mdi-cash', itens: []}
                item.itens.push({title: 'Fatura', router: '/financeiro/fatura'})

                /*if (this.permissao('financeiro_cadastrar')) {
                    item.itens.push({title: '* Contas a receber', router: '/financeiro/receber'})
                }
                if (this.permissao('financeiro_listar')) {
                    item.itens.push({title: '* Contas a pagar', router: '/financeiro/pagar'})
                }*/
                menu.push(item)
            }

            // Tradutor
            item = {title: '* Tradutor', icon: 'mdi-book-alphabet', router : '/tradutor'}
            //menu.push(item)

            // Chamados
            item = {title: 'Ajuda', icon: 'mdi-help-circle', itens: []}
            item.itens.push({title: 'Listar chamados', router: '/chamado'})
            menu.push(item)

            // Usuários
            if (this.permissao('usuario_cadastrar') || this.permissao('usuario_listar')) {
                menu.push({title: 'Usuários', icon: 'mdi-account', router: '/usuario'})
            }
            this.menuPrincipal = menu;
        },

    },
    data: () => ({
        drawer : null,
        title : null,
        tempoDeslogar : 60,
        deslogarInterval : null,
        menuPrincipal : []
    }),
    watch : {
        usuarioLogado : function(logado) {
            if(logado && this.usuarioSessao.inadimplente) {
                // desloga o usuário em 60 segundos
                this.deslogarInterval = setInterval(() => {
                    this.tempoDeslogar--
                    if(this.tempoDeslogar === 0 ) {
                        this.sair()
                    }
                }, 1000)
            }
            this.validarToken()
            this.getMenuPrincipal()
        },
        '$route' (to){
            this.title = to.meta.title.replace(' | Excellent Global - Escola', '') || 'Excellent Global - Escola'
        }
    },
    async mounted() {
        await this.validarToken()
        await this.getMenuPrincipal()
    },
    async created() {
        await this.validarToken()
        await this.getMenuPrincipal()
    }
}
</script>
