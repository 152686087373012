import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

let nameApp = ' | Excellent Global - Escola'

function verificarPermissao(permissao) {
    // eslint-disable-next-line
    let uSessao = JSON.parse(localStorage.getItem('excellentglobal'))
    if(uSessao.permissoes === undefined) {
        return false
    }
    return uSessao.permissoes.filter((v) => {
        return v.id_permissao == permissao
    }).length > 0
}

function uSessao() {
    return JSON.parse(localStorage.getItem('excellentglobal'))
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
      {
          path: '/',
          name: 'Dashboard',
          props : true,
          component: () => import('./Views/Dashboard/Dashboard'),
          meta : {
              title : 'Dashboard' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      },
      {
          path: '/aluno',
          name: 'Aluno',
          props: true,
          component: () => import('./Views/Aluno/Aluno'),
          meta: {
              title: 'Alunos' + nameApp
          },
          beforeEnter(to, from, next) {
              verificarPermissao('aluno_listar') ? next() : next('/')
          }
      },
      {
          path: '/aluno/cadastrar',
          name: 'AlunoCadastrar',
          props : true,
          component: () => import('./Views/Aluno/AlunoForm'),
          meta : {
              title : 'Cadastrar aluno' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aluno_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/aluno/importar',
          name: 'AlunoImportar',
          props : true,
          component: () => import('./Views/Aluno/AlunoImportar'),
          meta : {
              title : 'Importar alunos' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aluno_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/aluno/alterar/:id_aluno',
          name: 'AlunoAlterar',
          props : true,
          component: () => import('./Views/Aluno/AlunoForm'),
          meta : {
              title : 'Alterar aluno' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aluno_editar') ? next() : next('/')
          }
      },
      {
          path: '/aluno/nivel_incluir/:id_aluno',
          name: 'AlunoNivelForm',
          props : true,
          component: () => import('./Views/Aluno/AlunoNivelForm'),
          meta : {
              title : 'Incluir nível e material' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aluno_editar') ? next() : next('/')
          }
      },
      {
          path: '/livro/conteudo/:id_conteudo',
          name: 'LivroConteudo',
          props : true,
          component: () => import('./Views/Livro/LivroConteudo'),
          meta : {
              title : 'Livro' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      },
      {
          path: '/aluno/gerenciar/:id_aluno',
          name: 'AlunoGerenciar',
          props: true,
          component: () => import('./Views/Aluno/AlunoGerenciar'),
          meta: {
              title: 'Gerenciar aluno' + nameApp
          },
          beforeEnter(to, from, next) {
              verificarPermissao('aluno_editar') ? next() : next('/')
          }
      },
      {
          path: '/aula',
          name: 'Aula',
          props : true,
          component: () => import('./Views/Aula/Aula'),
          meta : {
              title : 'Aulas' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aula_listar') ? next() : next('/')
          }
      },
      {
          path: '/aula/gerenciar/:id_aula',
          name: 'AulaGerenciar',
          props : true,
          component: () => import('./Views/Aula/AulaGerenciar'),
          meta : {
              title : 'Gerenciar aula' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aula_abrir') ? next() : next('/')
          }
      },
      {
          path: '/aula/cadastrar/:id_turma',
          name: 'AulaCadastrar',
          props : true,
          component: () => import('./Views/Aula/AulaForm'),
          meta : {
              title : 'Cadastrar aula' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aula_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/aula/alterar/:id_aula',
          name: 'AulaAlterar',
          props : true,
          component: () => import('./Views/Aula/AulaForm'),
          meta : {
              title : 'Alterar aula' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('aula_editar') ? next() : next('/')
          }
      },
      {
          path: '/disciplina',
          name: 'Disciplina',
          props : true,
          component: () => import('./Views/Disciplina/Disciplina'),
          meta : {
              title : 'Disciplinas' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('disciplina_listar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/disciplina/cadastrar',
          name: 'DisciplinaCadastrar',
          props : true,
          component: () => import('./Views/Disciplina/DisciplinaForm'),
          meta : {
              title : 'Nova Disciplina' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('disciplina_cadastrar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/disciplina/alterar/:id_disciplina',
          name: 'DisciplinaAlterar',
          props : true,
          component: () => import('./Views/Disciplina/DisciplinaForm'),
          meta : {
              title : 'Alterar Disciplina' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('disciplina_editar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      // gerenciar = manager that show the information of discipline
      {
          path: '/disciplina/gerenciar/:id_disciplina',
          name: 'DisciplinaGerenciar',
          props : true,
          component: () => import('./Views/Disciplina/DisciplinaGerenciar'),
          meta : {
              title : 'Gerenciar disciplina' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('disciplina_abrir') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/financeiro/fatura',
          name: 'FinanceiroFatura',
          props : true,
          component: () => import('./Views/Financeiro/FinanceiroFatura'),
          meta : {
              title : 'Faturas' + nameApp
          },
          beforeEnter(to, from , next) {
              uSessao().id_grupo == '1' || uSessao().id_grupo == '2' ? next() : next('/')
          }
      },
      {
          path: '/financeiro/receber',
          name: 'FinanceiroReceber',
          props : true,
          component: () => import('./Views/Financeiro/FinanceiroReceber'),
          meta : {
              title : 'Contas a receber' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
              //verificarPermissao('dashboard') ? next() : next('/')
          }
      },
      {
          path: '/financeiro/pagar',
          name: 'FinanceiroPagar',
          props : true,
          component: () => import('./Views/Financeiro/FinanceiroPagar'),
          meta : {
              title : 'Contas a pagar' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
              //verificarPermissao('dashboard') ? next() : next('/')
          }
      },
      {
          path: '/login',
          name: 'Login',
          props: true,
          component: () => import('./Views/Login/Login'),
          meta: {
              title: 'Login' + nameApp
          },
          beforeEnter(to, from, next) {
              next()
          }
      }, {
          path: '/login/recuperar_senha/:id_usuario/:token',
          name: 'LoginNovaSenha',
          props: true,
          component: () => import('./Views/Login/LoginNovaSenha'),
          meta: {
              title: 'Recuperar senha' + nameApp
          }
      },
      {
          path: '/responsavel',
          name: 'Responsavel',
          props : true,
          component: () => import('./Views/Responsavel/Responsavel'),
          meta : {
              title : 'Responsáveis' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('responsavel_listar') ? next() : next('/')
          }
      },
      {
          path: '/responsavel/alterar/:id_responsavel',
          name: 'ResponsavelAlterar',
          props : true,
          component: () => import('./Views/Responsavel/ResponsavelForm'),
          meta : {
              title : 'Alterar responsável' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('responsavel_editar') ? next() : next('/')
          }
      },
      {
          path: '/responsavel/cadastrar',
          name: 'ResponsavelCadastrar',
          props : true,
          component: () => import('./Views/Responsavel/ResponsavelForm'),
          meta : {
              title : 'Cadastrar responsável' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('responsavel_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/responsavel/gerenciar/:id_responsavel',
          name: 'ResponsavelGerenciar',
          props : true,
          component: () => import('./Views/Responsavel/ResponsavelGerenciar'),
          meta : {
              title : 'Gerenciar responsável' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('responsavel_abrir') ? next() : next('/')
          }
      },
      {
          path: '/franquia',
          name: 'Franquia',
          props : true,
          component: () => import('./Views/Franquia/Franquia'),
          meta : {
              title : 'Franquia' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('franquia_listar') ? next() : next('/')
          }
      },
      {
          path: '/franquia/cadastrar',
          name: 'FranquiaCadastrar',
          props : true,
          component: () => import('./Views/Franquia/FranquiaForm'),
          meta : {
              title : 'Nova franquia' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('franquia_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/franquia/alterar/:id_franquia',
          name: 'FranquiaAlterar',
          props : true,
          component: () => import('./Views/Franquia/FranquiaForm'),
          meta : {
              title : 'Alterar franquia' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('franquia_editar') ? next() : next('/')
          }
      },
      {
          path: '/franquia/gerenciar/:id_franquia',
          name: 'FranquiaGerenciar',
          props : true,
          component: () => import('./Views/Franquia/FranquiaGerenciar'),
          meta : {
              title : 'Gerenciar franquia' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('franquia_abrir') ? next() : next('/')
          }
      },
      {
          path: '/chamado',
          name: 'Chamado',
          props : true,
          component: () => import('./Views/Chamado/Chamado'),
          meta : {
              title : 'Chamados' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      },
      {
          path: '/chamado/gerenciar/:id_chamado',
          name: 'ChamadoGerenciar',
          props : true,
          component: () => import('./Views/Chamado/ChamadoGerenciar'),
          meta : {
              title : 'Gerenciar chamado' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      },
      {
          path: '/chamado/novo',
          name: 'ChamadoNovo',
          props : true,
          component: () => import('./Views/Chamado/ChamadoForm.vue'),
          meta : {
              title : 'Novo chamado' + nameApp
          },
          beforeEnter(to, from , next) {
              next()
          }
      },
      {
          path: '/curso',
          name: 'Curso',
          props : true,
          component: () => import('./Views/Curso/Curso'),
          meta : {
              title : 'Cursos' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('curso_listar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/curso/cadastrar',
          name: 'CursoCadastrar',
          props : true,
          component: () => import('./Views/Curso/CursoForm'),
          meta : {
              title : 'Nova curso' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('curso_cadastrar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/curso/alterar/:id_curso',
          name: 'CursoAlterar',
          props : true,
          component: () => import('./Views/Curso/CursoForm'),
          meta : {
              title : 'Alterar curso' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('curso_editar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/curso/gerenciar/:id_curso',
          name: 'CursoGerenciar',
          props : true,
          component: () => import('./Views/Curso/CursoGerenciar'),
          meta : {
              title : 'Gerenciar curso' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('curso_abrir') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/turma/cadastrar',
          name: 'TurmaCadastrar',
          props : true,
          component: () => import('./Views/Turma/TurmaForm'),
          meta : {
              title : 'Cadastrar turma' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('turma_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/turma/aluno_incluir/:id_turma',
          name: 'TurmaAlunoNovo',
          props : true,
          component: () => import('./Views/Turma/TurmaAlunoIncluirForm'),
          meta : {
              title : 'Incluir aluno' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('turma_editar') ? next() : next('/')
          }
      },
      {
          path: '/turma/alterar/:id_turma',
          name: 'TurmaAlterar',
          props : true,
          component: () => import('./Views/Turma/TurmaForm'),
          meta : {
              title : 'Alterar turma' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('turma_editar') ? next() : next('/')
          }
      },
      {
          path: '/turma',
          name: 'Turma',
          props : true,
          component: () => import('./Views/Turma/Turma'),
          meta : {
              title : 'Turmas' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('turma_listar') ? next() : next('/')
          }
      },
      {
          path: '/turma/gerenciar/:id_turma',
          name: 'TurmaGerenciar',
          props : true,
          component: () => import('./Views/Turma/TurmaGerenciar'),
          meta : {
              title : 'Gerenciar turma' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('turma_abrir') ? next() : next('/')
          }
      },
      {
          path: '/usuario',
          name: 'Usuario',
          props : true,
          component: () => import('./Views/Usuario/Usuario'),
          meta : {
              title : 'Usuários' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_listar') && (uSessao().id_grupo == '1' || uSessao().id_grupo == '2') ? next() : next('/')
          }
      },
      {
          path: '/usuario/cadastrar',
          name: 'UsuarioCadastrar',
          props : true,
          component: () => import('./Views/Usuario/UsuarioForm'),
          meta : {
              title : 'Cadastrar usuário' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_cadastrar') ? next() : next('/')
          }
      },
      {
          path: '/usuario/alterar/:id_usuario',
          name: 'UsuarioAlterar',
          props : true,
          component: () => import('./Views/Usuario/UsuarioForm'),
          meta : {
              title : 'Alterar usuário' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_editar') ? next() : next('/')
          }
      },
      {
          path: '/usuario/gerenciar/:id_usuario',
          name: 'UsuarioGerenciar',
          props : true,
          component: () => import('./Views/Usuario/UsuarioGerenciar'),
          meta : {
              title : 'Gerenciar usuário' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_abrir') ? next() : next('/')
          }
      },
      {
          path: '/usuario/grupo',
          name: 'UsuarioGrupo',
          props : true,
          component: () => import('./Views/Usuario/Grupo'),
          meta : {
              title : 'Grupo' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_grupo_listar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/usuario/grupo/gerenciar/:id_grupo',
          name: 'UsuarioGrupoGerenciar',
          props : true,
          component: () => import('./Views/Usuario/UsuarioGrupoGerenciar'),
          meta : {
              title : 'Gerenciar grupo' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_grupo_abrir') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/usuario/level_incluir/:id_usuario',
          name: 'UsuarioLevelForm',
          props : true,
          component: () => import('./Views/Usuario/UsuarioLevelForm'),
          meta : {
              title : 'Incluir level' + nameApp
          },
          beforeEnter(to, from , next) {
              verificarPermissao('usuario_editar') && uSessao().id_grupo == '1' ? next() : next('/')
          }
      },
      {
          path: '/usuario/senha_alterar',
          name: 'UsuarioSenhaAlterar',
          props: true,
          component: () => import('./Views/Usuario/UsuarioSenhaForm.vue'),
          meta: {
              title: 'Alterar minha senha' + nameApp
          }
      },
      {
          path: '/usuario/imagem_alterar',
          name: 'UsuarioImagemAlterar',
          props: true,
          component: () => import('./Views/Usuario/UsuarioImagemForm.vue'),
          meta: {
              title: 'Alterar minha foto' + nameApp
          }
      }
  ]
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title
    next()
})


router.afterEach((to) => {
    // eslint-disable-next-line
    gtag('event', 'page_view', {
        send_to    : 'G-HSZK1CJGL6',
        // eslint-disable-next-line
        page_path  : to.path,
        page_title : document.title
    })
})


export default router
