<template>
    <v-row align="center" justify="center">
        <v-col cols="8">
            <h1>Olá!</h1>
            <h2>Seja bem vindo(a)</h2>
        </v-col>
        <v-col cols="8">
            <v-form>
                <v-row>
                    <v-col cols="12"><v-text-field hide-details label="E-mail:" type="email" v-model="usuario.email" @keyup.enter="logar" /></v-col>
                    <v-col v-if="!recuperar_senha" cols="12"><v-text-field hide-details label="Senha:" type="password" v-model="usuario.senha" @keyup.enter="logar" /></v-col>
                </v-row>
            </v-form>
        </v-col>
        <v-col cols="8" class="text-center">
            <v-alert class="mt-5" v-if="erro" type="error" style="text-align: left">{{erro}}</v-alert>
            <v-alert class="mt-5" v-if="sucesso" type="success" style="text-align: left">{{sucesso}}</v-alert>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-btn :loading="carregando" v-if="recuperar_senha" @click="recuperar_senha = false" elevation="0" color="default" class="mr-4" text><v-icon left>mdi-arrow-left</v-icon>Cancelar</v-btn>
            <v-btn :loading="carregando" @click="recuperarSenha" elevation="0" color="default" class="mr-4" text><v-icon left>mdi-lock</v-icon>Recuperar senha</v-btn>
            <v-btn :loading="carregando" v-if="!recuperar_senha" @click="logar" elevation="0" color="primary"><v-icon left>mdi-login</v-icon>Login</v-btn>
        </v-col>
    </v-row>
</template>

<script>

    import axios from 'axios'
    import {mapState} from 'vuex'

    export default {
        name: "Login",
        data() {
            return {
                usuario : {},
                sucesso : null,
                carregando : false,
                recuperar_senha : false,
                erro : null
            }
        },
        computed : {
            ...mapState(['apiUrl', 'usuarioChave'])
        },
        methods : {
            logar() {
                this.carregando = true
                axios.post(`${this.apiUrl}login`, this.usuario).then(res => {
                    this.$store.commit('usuarioLogin', res.data)
                    localStorage.setItem(this.usuarioChave, JSON.stringify(res.data))
                    this.erro = null
                    this.$router.push('/')
                }).catch(e => {
                    this.erro       = this.erroFormatar(e)
                    this.carregando = false
                })
            },
            recuperarSenha() {
                if(this.recuperar_senha) {
                    this.carregando = true
                    axios.post(`${this.apiUrl}login/senha/recuperar`, this.usuario).then(() => {
                        this.erro            = null
                        this.sucesso         = 'Operação realizada com sucesso, siga as instruções contidas no e-mail.'
                        this.carregando      = false
                        // Direcionar o usuário para a tela de login após 10 segundos
                        setTimeout(() => {
                            this.recuperar_senha = false
                            this.sucesso = null
                        }, 10 * 1000)
                    }).catch(e => {
                        this.erro       = this.erroFormatar(e)
                        this.sucesso    = null
                        this.carregando = false
                    })
                } else {
                    this.recuperar_senha = true
                }
            }
        }
    }
</script>

<style scoped>
    h1, h2 {color: #0E5381; text-align: center;}
    h1 {font-size:50px;}
    h2 {font-size:30px;}
    p {text-align: center;}
</style>
