<template>
    <v-row align="center" justify="center">
        <v-col cols="8">
            <h1>Olá!</h1>
            <h2>Seja bem vindo(a)</h2>
        </v-col>
        <v-col cols="8">
            <v-form autocomplete="off" novalidate="novalidate">
                <v-row>
                    <v-col cols="12"><v-text-field hide-details label="Nova senha:" type="password" v-model="usuario.nsenha" @keyup.enter="novaSenha" autocomplete="new-password" /></v-col>
                    <v-col cols="12"><v-text-field hide-details label="Repita a senha:" type="password" v-model="usuario.rsenha" @keyup.enter="novaSenha" autocomplete="new-password" /></v-col>
                </v-row>
            </v-form>
        </v-col>
        <v-col cols="8" class="text-center">
            <v-alert class="mt-5" v-if="erro" type="error" style="text-align: left">{{erro}}</v-alert>
            <v-alert class="mt-5" v-if="sucesso" type="success" style="text-align: left">{{sucesso}}</v-alert>
        </v-col>
        <v-col cols="12" class="text-center">
            <v-btn :loading="carregando" @click="$router.push('/login')" elevation="0" color="default" class="mr-4" text><v-icon left>mdi-arrow-left</v-icon>Cancelar</v-btn>
            <v-btn :loading="carregando" @click="novaSenha" elevation="0" color="default" class="mr-4" text><v-icon left>mdi-lock</v-icon>Alterar senha</v-btn>
        </v-col>
    </v-row>
</template>

<script>

import axios from 'axios'
import {mapState} from 'vuex'

export default {
    name: "LoginNovaSenha",
    data() {
        return {
            usuario : {},
            sucesso : null,
            carregando : false,
            erro : null
        }
    },
    computed : {
        ...mapState(['baseUrl', 'apiUrl', 'usuarioChave'])
    },
    methods : {
        novaSenha() {
            this.carregando = true
            axios.post(`${this.apiUrl}login/senha/alterar`, {
                ...this.usuario,
                id_usuario : parseInt(this.$router.currentRoute.params.id_usuario),
                token      : this.$router.currentRoute.params.token
            }).then(() => {
                this.erro       = null
                this.sucesso    = 'Operação realizada com sucesso, você será direcionado para a tela de login em 10 segundos.'
                this.carregando = false
                // Direcionar o usuário para a tela de login após 10 segundos
                setTimeout(() => {
                    this.sucesso = null
                    this.$router.push('/login')
                }, 10 * 1000)
            }).catch(e => {
                this.erro       = this.erroFormatar(e)
                this.sucesso    = null
                this.carregando = false
            })
        }
    }
}
</script>

<style scoped>
h1, h2 {color: #0E5381; text-align: center;}
h1 {font-size:50px;}
h2 {font-size:30px;}
p {text-align: center;}
</style>
