import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

import pt from 'vuetify/es5/locale/pt'

export default new Vuetify({
    lang: {
        locales : {pt},
        current : 'pt'
    },
    theme : {
        themes : {
            light: {
                primary : '#0E5381',
                secondary: '#F6B30F', // #FFCDD2
                accent: '#999999', // #3F51B5
            }
        }
    }
});