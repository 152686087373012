import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        apiUrl : 'https://excellentglobal-escola-api-whiyyqnxmq-rj.a.run.app/', // API Golang // 'https://excellentglobal-escola-api-whiyyqnxmq-rj.a.run.app/' // 'http://localhost:81/'
        baseUrl : 'https://excellentglobal-api-whiyyqnxmq-rj.a.run.app/', // API PHP // 'https://excellentglobal-api-whiyyqnxmq-rj.a.run.app/' || 'http://localhost/'
        alunoAppUrl : 'https://app.excellentglobal.com.br/', // URL do APP do aluno // 'https://app.excellentglobal.com.br/' // 'http://localhost:8081/'
        axiosWaitTimeout : 350, // tempo em milissegundos
        paginationLimit : 20,
        paginationLimitGerenciar : 10,
        usuarioLogado : false,
        usuarioChave : 'excellentglobal',
        usuarioSessao : {},
    },
    mutations : {
        usuarioLogin(state, usuario) {
            state.usuarioSessao = usuario
            if (usuario) {
                axios.defaults.headers.common['authorization'] = usuario.sessao_token;
                state.usuarioLogado = true;
            } else {
                delete axios.defaults.headers.common['authorization'];
                state.usuarioLogado = false;
            }
        }
    },
    actions: {

    }
})
